// import then needed Font Awesome functionality
import { library, dom } from "@fortawesome/fontawesome-svg-core";
// import the Facebook and Twitter icons
import {
  faAngleUp,
  faAngleRight,
  faAngleLeft,
  faHouseChimney,
  faMagnifyingGlass,
  faMap,
  faMapPin,
  faMapLocation,
  faMapLocationDot,
  faLocationDot,
  faLocationPin,
  faStar,
  faCircleInfo,
  faEnvelope,
  faUsers,
  faUser,
  faBed,
  faShower,
  faPaw,
  faCheck,
  faImage,
  faImages
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faSquareFacebook,
  faFacebookMessenger,
  faInstagram,
  faSquareInstagram,
  faYoutube,
  faSquareYoutube,
  faWhatsapp,
  faTelegram
} from "@fortawesome/free-brands-svg-icons";

// add the imported icons to the library
library.add(
  faAngleUp,
  faAngleRight,
  faAngleLeft,
  faHouseChimney,
  faMagnifyingGlass,
  faMap,
  faMapPin,
  faMapLocation,
  faMapLocationDot,
  faLocationDot,
  faLocationPin,
  faStar,
  faCircleInfo,
  faEnvelope,
  faUsers,
  faUser,
  faBed,
  faShower,
  faPaw,
  faCheck,
  faImage,
  faImages,
  faFacebook,
  faSquareFacebook,
  faFacebookMessenger,
  faInstagram,
  faSquareInstagram,
  faYoutube,
  faSquareYoutube,
  faWhatsapp,
  faTelegram
);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

if (typeof browser === "undefined") {
  var browser = chrome;
}

// Import everything from autoload
import "./package/bootstrap";
import "./package/fortawesome";

import * as bootstrap from "bootstrap";

(function () {
  "use strict"; // Start of use strict

  // application code
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
  const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))

  var btnBar = document.querySelector(".btn-bar");

  var navBarTop = document.querySelector(".navbar-top");
  var btnTop = document.querySelector(".btn-top");

  if (navBarTop || btnTop) {
    window.onscroll = function () {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        navBarTop.classList.add("down");
        btnTop.classList.add("show");
      } else {
        navBarTop.classList.remove("down");
        btnTop.classList.remove("show");
      }
    };

    btnTop.addEventListener("click", function () {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    });
  }
})(); // End of use strict
